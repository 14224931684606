<template>
  <div>
     <div>
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Geo Info</span>
    </div>

    <div class="mt-4 ">
      <label class=" vs-input--label">Region</label>
      <v-select 
        :value="geo.hotel_region_id"
        @input="updateForm({key: 'hotel_region_id', value: $event})"
        :options="hotel_regions" />
        <span class="text-danger text-sm" 
      v-show="validation.hotel_region_id?true:false">
      {{ validation.hotel_region_id?validation.hotel_region_id[0]:''}}
    </span>
    </div>
    <div class="mt-4 ">
        <vs-input
          class="w-full mt-4"
          label="Latitude"
          :danger="validation.latitude?true:false"
          :danger-text="validation.latitude?validation.latitude[0]:''"
          :value="form.latitude"
          @input="updateForm({key: 'latitude', value: $event})" />
    </div>
    
    <div class="mt-4 ">
          <vs-input
          class="w-full mt-4"
          label="Longitude"
          :danger="validation.longitude?true:false"
          :danger-text="validation.longitude?validation.longitude[0]:''"
          :value="form.longitude"
          @input="updateForm({key: 'longitude', value: $event})" />
    </div>
    <div class="mt-4">
        <vs-input 
        class="w-full mt-4" 
        label="Google Map Code" 
        :danger="validation.google_map?true:false"
        :danger-text="validation.google_map?validation.google_map[0]:''"
        :value="form.google_map"
        @input="updateForm({key: 'google_map', value: $event})" />
    </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {

    }
  },
  components: {
    vSelect,
    vOption
  },

  mounted() {
    this.fetchHotelRegions();
  },
  computed: {
    ...mapGetters({
        form: 'hotel/getFormObj',
        geo: 'hotel/getGeo',
        validation: 'validation/getValidationErrors',
        hotel_regions: 'hotel_region/getHotelRegionsDropdown',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'hotel/updateForm',
      fetchHotelRegions: 'hotel_region/fetchAndSetHotelRegionsDropdown',
    })
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
