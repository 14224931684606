<template>
  <div class="mb-6">     
       <vs-checkbox 
          class="w-full mt-4" 
          :checked="form.is_sponsored"
          :value="form.is_sponsored"
          @change="updateForm({key: 'is_sponsored', value: $event.target.checked})"> 
          Sponsored 
        </vs-checkbox>
        
       <vs-checkbox 
          class="w-full mt-4" 
          :checked="form.status"
          :value="form.status"
          @change="updateForm({key: 'status', value: $event.target.checked})"> 
          Staus 
        </vs-checkbox>

           <vs-checkbox 
            class="w-full mt-4" 
            :checked="form.is_full_payment"
            :value="form.is_full_payment"
          @change="updateForm({key: 'is_full_payment', value: $event.target.checked})"> 
          Full Payment (At the time of Booking)? 
        </vs-checkbox>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
        form: 'hotel/getFormObj',
        validation: 'validation/getValidationErrors'
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'hotel/updateForm'
    })
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
