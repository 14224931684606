<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="HomeIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Hotel Details</span>
      </div>
      <div class="vx-col w-full">
        <div class="flex flex-wrap -mx-3 mb-6">
          <vs-input
            :danger="validation.name ? true : false"
            :danger-text="validation.name ? validation.name[0] : ''"
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Name"
            :value="form.name"
            @input="updateForm({ key: 'name', value: $event })"
          />

          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Slug"
            :danger="validation.slug ? true : false"
            :danger-text="validation.slug ? validation.slug[0] : ''"
            :value="form.slug"
            @input="updateForm({ key: 'slug', value: $event })"
          />
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label class="vs-input--label">Owner</label>
            <v-select
              :danger="validation.user_id ? true : false"
              :value="geo.user_id"
              :options="users"
              @input="updateForm({ key: 'user_id', value: $event })"
            />
            <span
              class="text-danger text-sm"
              v-show="validation.user_id ? true : false"
            >
              {{ validation.user_id ? validation.user_id[0] : "" }}
            </span>
          </div>
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Email"
            :danger="validation.email ? true : false"
            :danger-text="validation.email ? validation.email[0] : ''"
            :value="form.email"
            @input="updateForm({ key: 'email', value: $event })"
          />
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Website"
            :danger="validation.website ? true : false"
            :danger-text="validation.website ? validation.website[0] : ''"
            :value="form.website"
            @input="updateForm({ key: 'website', value: $event })"
          />
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Default price"
            :danger="validation.default_price ? true : false"
            :danger-text="
              validation.default_price ? validation.default_price[0] : ''
            "
            :value="form.default_price"
            @input="updateForm({ key: 'default_price', value: $event })"
          />
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Min payment percent"
            :danger="validation.min_payment_percent ? true : false"
            :danger-text="
              validation.min_payment_percent
                ? validation.min_payment_percent[0]
                : ''
            "
            :value="form.min_payment_percent"
            @input="updateForm({ key: 'min_payment_percent', value: $event })"
          />
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Primary phone"
            :danger="validation.phone_1 ? true : false"
            :danger-text="validation.phone_1 ? validation.phone_1[0] : ''"
            :value="form.phone_1"
            @input="updateForm({ key: 'phone_1', value: $event })"
          />
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Secondary phone"
            :danger="validation.phone_2 ? true : false"
            :danger-text="validation.phone_2 ? validation.phone_2[0] : ''"
            :value="form.phone_2"
            @input="updateForm({ key: 'phone_2', value: $event })"
          />
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Primary Address"
            :danger="validation.address_1 ? true : false"
            :danger-text="validation.address_1 ? validation.address_1[0] : ''"
            :value="form.address_1"
            @input="updateForm({ key: 'address_1', value: $event })"
          />
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Secondary Address"
            :danger="validation.address_2 ? true : false"
            :danger-text="validation.address_2 ? validation.address_2[0] : ''"
            :value="form.address_2"
            @input="updateForm({ key: 'address_2', value: $event })"
          />

          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Star rating"
            :danger="validation.star_rating ? true : false"
            :danger-text="
              validation.star_rating ? validation.star_rating[0] : ''
            "
            :value="form.star_rating"
            @input="updateForm({ key: 'star_rating', value: $event })"
          />

          <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Position"
            :danger="validation.position ? true : false"
            :danger-text="validation.position ? validation.position[0] : ''"
            :value="form.position"
            @input="updateForm({ key: 'position', value: $event })"
          />
        </div>

        <div class="vx-col w-full md:w-1/3">
        <label class="vs-input--label">Tag</label>
        <v-select
          :danger="validation.tag ? true : false"
          :value="form_dropdown.tag"
          @input="updateForm({ key: 'tag', value: $event })"
          :options="tags"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.tag ? true : false"
        >
          {{ validation.tag ? validation.tag[0] : "" }}
        </span>
      </div>

        <div class="w-full mt-4">
          <label>Amenities</label>
          <v-select
            multiple
            :danger="validation.amenities ? true : false"
            :value="geo.amenities"
            :options="amenities"
            @input="handleMultipleSelect({ key: 'amenities', value: $event })"
          />

          <span
            class="text-danger text-sm"
            v-show="validation.amenities ? true : false"
          >
            {{ validation.amenities ? validation.amenities[0] : "" }}
          </span>
        </div>

        <div class="w-full mt-4">
          <label>Available Features</label>
          <v-select
            multiple
            :danger="validation.available_features ? true : false"
            :value="geo.available_features"
            :options="available_features"
            @input="
              handleMultipleSelect({ key: 'available_features', value: $event })
            "
          />

          <span
            class="text-danger text-sm"
            v-show="validation.available_features ? true : false"
          >
            {{
              validation.available_features
                ? validation.available_features[0]
                : ""
            }}
          </span>
        </div>

        <div class="w-full mt-4">
          <label>Description</label>
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            :value="form.description"
            @input="updateForm({ key: 'description', value: $event })"
          ></ckeditor>
          <span
            class="text-danger text-sm"
            v-show="validation.description ? true : false"
          >
            {{ validation.description ? validation.description[0] : "" }}
          </span>
        </div>

        <div class="w-full mt-4">
          <label for="features">Features</label>
          <ckeditor
            :editor="editor"
            :config="editorConfig"
            :value="form.features"
            @input="updateForm({ key: 'features', value: $event })"
          ></ckeditor>
          <span
            class="text-danger text-sm"
            v-show="validation.features ? true : false"
          >
            {{ validation.features ? validation.features[0] : "" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect, { vOption } from "vue-select";
import { mapGetters, mapActions } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // toolbar: [ 'bold', 'italic', '|', 'link' ]
      },
    };
  },
  mounted() {
    this.fetchUsers();
    let params = new URLSearchParams();
    params.append("query[type]", "hotel");
    this.fetchAmenities({ query: params });
    this.fetchFeatures();
  },
  components: {
    vSelect,
    vOption,
  },
  computed: {
    ...mapGetters({
      geo: "hotel/getGeo",
      users: "user_dropdown/fetchUsersDropdown",
      amenities: "homestay_amenities/fetchAmenitiesDropdown",
      form: "hotel/getFormObj",
      validation: "validation/getValidationErrors",
      available_features: "feature_dropdown/fetchFeaturesDropdown",
      form_dropdown: "hotel/getFormDropdownObj",
      tags: "hotel/getTags",
    }),
  },
  methods: {
    ...mapActions({
      createHotel: "hotel/createHotel",
      fetchAmenities: "homestay_amenities/fetchAndSetAmenitiesDropdown",
      fetchUsers: "user_dropdown/fetchAndSetUsersDropdown",
      updateForm: "hotel/updateForm",
      fetchFeatures: "feature_dropdown/fetchAndSetFeaturesDropdown",
    }),
    handleMultipleSelect($event) {
      this.updateForm($event);
    },
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
